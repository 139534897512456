<template>
  <div>
    <h2>Экологический паспорт предприятия</h2>
    <el-form
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
    <el-form-item label="Период">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy"
              v-model="reportForm.date"
              type="year"
              @change="changeDate(reportForm.date)"
            >
            </el-date-picker>
          </el-col>
        </el-row>
    </el-form-item>
    
    <el-form-item label="Отходы">
      <el-table stripe :data="wastes" v-if="wastes[0].organizationWasteId!=null">
        <el-table-column label="Код" prop="ss01WasteDictionaryDTO.wasteCode"></el-table-column>
        <el-table-column label="Отход" prop="">
          <template slot-scope="item">
            {{ item.row.ss01WasteDictionaryDTO.wasteName+"-"+item.row.ss01WasteDictionaryDTO.dangerDegreeClassifierDTO.name }}
          </template>
        </el-table-column>
        <el-table-column label="Отход" prop="">
          <template slot-scope="item">
            <input type="checkbox" id="checkbox" v-model="item.row.in">
          </template>
        </el-table-column>
        <el-table-column label="Ртуть" prop="">
          <template slot-scope="item">
            <input type="checkbox" id="checkbox" v-model="item.row.p1">
          </template>
        </el-table-column>
        <el-table-column label="ПХБ" prop="">
          <template slot-scope="item">
            <input type="checkbox" id="checkbox" v-model="item.row.p2">
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="Тип файла">
            <el-select
              clearable
              v-model="reportForm.fileType"
              placeholder="Формат отчета"
            >
              <el-option
                v-for="item in fileTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
    <el-form-item>
      <el-button @click="downloadReport" type="primary"
                >Сформировать отчет</el-button>
    </el-form-item>

    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AXIOS } from "@/AXIOS/http-common";

export default {
  name: "eppReport",
  //components: { SearchBlock },
  data() {
    return {
      wastes:
      [
        {
        organizationWasteId: null,
        p1:null,
        p2:null,
        in:null,
        ss01WasteDictionaryDTO:{
          wasteName:null,
          wasteCode:null,
          dangerDegreeClassifierDTO:{
            name:null,
            }
          } 
        }
      ],
      reportForm: {
        date: null,
      },
      fileTypes: [
        {
          value: ".pdf",
          label: ".pdf",
        },
        {
          value: ".xls",
          label: ".xls",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
     
    }),
  },
  methods: {
    async changeDate(date)
    {
      await AXIOS.get("organization-waste/listepp?year=" + date).then(
      (response) => {
        console.log("response.data");
        console.log(response.data);
        this.wastes=response.data.sort((a, b) => {
        const nameA = a.ss01WasteDictionaryDTO.wasteCode; // ignore upper and lowercase
        const nameB = b.ss01WasteDictionaryDTO.wasteCode; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
              });
        
        this.wastes.forEach(waste => {
          waste.p1=(waste.pss05MercuryInfo.id==1) ? true : false;
          waste.p2=(waste.phb.id==1) ? true : false;
          waste.in=true;
        });
      });
    },
    downloadReport() {
      
        this.$store.dispatch("downloadEpp", {wastes:this.wastes,year:parseInt(this.reportForm.date),fileTypes:this.reportForm.fileType});
      },
    
  },


  mounted() {
    
  },
};
</script>

<style scoped></style>

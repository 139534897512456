<template>
  <div>
    <epp-report></epp-report>
  </div>
</template>

<script>
import eppReport from "@/components/ecologist/reports/epp/eppReport";
export default {
  name: "eppView",
  components: { eppReport },
};
</script>

<style scoped></style>